<template>
  <div>
    <h2 class="mb-5">Vapor Pressure of EtOH: Data & Calculations</h2>

    <p class="mb-3">a) Fill in the table below with the results/calculations of your experiment.</p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <tbody>
          <tr>
            <td rowspan="2" style="font-weight: bold; text-align: left">Property</td>
            <td rowspan="2" style="font-weight: bold; text-align: center">Initial</td>
            <td colspan="5" style="font-weight: bold; text-align: center">Trial</td>
          </tr>
          <tr>
            <td style="font-weight: bold; text-align: center">1</td>
            <td style="font-weight: bold; text-align: center">2</td>
            <td style="font-weight: bold; text-align: center">3</td>
            <td style="font-weight: bold; text-align: center">4</td>
            <td style="font-weight: bold; text-align: center">5</td>
          </tr>
          <tr>
            <td style="width: 16%">
              <stemble-latex content="$\text{P}_\text{tot}~\text{(kPa)}$" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input" style="width: 14%">
              <calculation-input
                v-model="inputs.pAirInit"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td
              v-for="answer in answers"
              class="py-2 px-2 mx-0 centered-input"
              style="width: 14%"
              :key="answer.pTot"
            >
              <calculation-input
                v-model="inputs[answer.pTot]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{P}_\text{air}~\text{(kPa)}$" /></td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pAirInit"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.pAir">
              <calculation-input
                v-model="inputs[answer.pAir]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{P}_\text{vap}~\text{(kPa)}$" /></td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pVapInit"
                class="centered-input"
                disabled
                value="N/A"
              />
            </td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.pVap">
              <calculation-input
                v-model="inputs[answer.pVap]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{T (}^\circ\text{C)}$" /></td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.TinCInit"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.TinC">
              <calculation-input
                v-model="inputs[answer.TinC]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-3">
      b) Using the data in the table above, construct a graph of
      <stemble-latex content="$\ln\left(\text{P}_\text{vap}\right)$" /> vs
      <stemble-latex content="$1/\text{T}$" /> (where T is in Kelvin). Be sure to label your axes
      properly and draw a line of best fit (be sure to show the equation and R<sup>2</sup> value).
      Then, upload your graph using the space provided below.
    </p>

    <file-upload-ui-component
      style="width: 100%"
      label="Click here to select a file"
      :max-number-of-files="1"
      :maxFileSize="10"
      class="mb-5"
      accepted-file-extensions=".png, .webp, .jpg ,.jpeg"
      @upload:attachments="ccPlot = $event"
    />

    <p class="mb-3">c) Enter the slope from the linear regression below:</p>

    <calculation-input
      v-model="inputs.slope"
      prepend-text="$\text{Slope:}$"
      append-text="$\text{K}$"
      class="mb-5"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      d) What is the enthalpy of vaporization,
      <stemble-latex content="$\Delta\text{H}_\text{vap},$" /> of ethanol based on your results in
      this experiment?
    </p>

    <calculation-input
      v-model="inputs.deltaHvap"
      prepend-text="$\Delta\text{H}_\text{vap}:$"
      append-text="$\text{kJ/mol}$"
      class="mb-5"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      e) Given that the accepted value of
      <stemble-latex content="$\Delta\text{H}_\text{vap}$" /> for ethanol is 42.32 kJ/mol, calculate
      the percent error for your experimental result.
    </p>

    <calculation-input
      v-model="inputs.pctError"
      prepend-text="$\text{Percent Error:}$"
      append-text="$\%$"
      class="mb-3"
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default {
  name: 'OleMissVaporPressureReportSheet',
  components: {
    FileUploadUiComponent,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pTotInit: null,
        pAirInit: null,
        TinCInit: null,
        pVapInit: null,

        pTotT1: null,
        pAirT1: null,
        pVapT1: null,
        TinCT1: null,

        pTotT2: null,
        pAirT2: null,
        pVapT2: null,
        TinCT2: null,

        pTotT3: null,
        pAirT3: null,
        pVapT3: null,
        TinCT3: null,

        pTotT4: null,
        pAirT4: null,
        pVapT4: null,
        TinCT4: null,

        pTotT5: null,
        pAirT5: null,
        pVapT5: null,
        TinCT5: null,

        slope: null,
        deltaHvap: null,
        pctError: null,
      },
      answers: [
        {
          pTot: 'pTotT1',
          pAir: 'pAirT1',
          pVap: 'pVapT1',
          TinC: 'TinCT1',
        },
        {
          pTot: 'pTotT2',
          pAir: 'pAirT2',
          pVap: 'pVapT2',
          TinC: 'TinCT2',
        },
        {
          pTot: 'pTotT3',
          pAir: 'pAirT3',
          pVap: 'pVapT3',
          TinC: 'TinCT3',
        },
        {
          pTot: 'pTotT4',
          pAir: 'pAirT4',
          pVap: 'pVapT4',
          TinC: 'TinCT4',
        },
        {
          pTot: 'pTotT5',
          pAir: 'pAirT5',
          pVap: 'pVapT5',
          TinC: 'TinCT5',
        },
      ],
      ccPlot: [],
    };
  },
  computed: {
    attachments() {
      return [...this.ccPlot];
    },
  },
};
</script>

<style></style>
